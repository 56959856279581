<template>
	<div class="wrapper">
		<section class="agreement-section">
			<div class="container">
				<div class="agreement">
					<tk-button @click="goBack" kind="back-button">
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						Назад
					</tk-button>

					<h1>Лицензионное соглашение</h1>
					<p>
						ВНИМАНИЕ! ЕСЛИ ВЫ СОЗДАЕТЕ ПЕРСОНАЛЬНУЮ СТРАНИЦУ (АККАУНТ), ЗАХОДИТЕ
						НА СВОЮ ПЕРСОНАЛЬНУЮ СТРАНИЦУ, ЛИБО ЗАГРУЖАЕТЕ ИЛИ КОПИРУЕТЕ ЛЮБОЙ
						РАЗМЕЩЕННЫЙ В НЕЙ КОНТЕНТ, ЛИБО ИНЫМ СПОСОБОМ ИСПОЛЬЗУЕТЕ
						ПРИЛОЖЕНИЕ, ТО ЭТИМ ВЫ АКЦЕПТУЕТЕ НАСТОЯЩЕЕ ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ
						И ПОДТВЕРЖДАЕТЕ СВОЕ СОГЛАСИЕ СО ВСЕМИ ЕГО УСЛОВИЯМИ БЕЗ КАКИХ-ЛИБО
						ОГРАНИЧЕНИЙ.
					</p>
					<p>
						Настоящее лицензионное соглашение (далее «Соглашение») регулирует
						отношения между ООО "ТЕРЕМОК", именуемое в дальнейшем «Лицензиар» и
						Вами, Лицензиатом приложения (Сервиса) (далее «Лицензиат»), в
						отношении использования приложения (Сервиса).
					</p>
					<ol>
						<li>
							<p>
								<strong>Термины, используемые в настоящем Соглашении</strong>
							</p>
							<ol>
								<li>
									<p>
										<strong>Приложение (Сервис)</strong> – приложение, известное
										под именем «TeremOK», размещенное на сайте в сети Интернет
										по адресу teremok.io и доступное Лицензиату через сайт,
										мобильную версию сайта, приложения и иные ресурсы,
										представляющие собой результат интеллектуальной деятельности
										в форме программы для ЭВМ. Приложение представлено в
										объективной форме совокупностью данных и команд, и
										порождаемых аудиовизуальных отображений (включая входящие в
										его состав графические изображения и пользовательский
										интерфейс), (далее – данные и команды), предназначенных для
										функционирования ЭВМ и мобильных устройств в целях получения
										определенного результата в виде организации функционала
										приложения. Совокупность данных и команд состоит из
										активированных и неактивированных данных и команд.
									</p>
									<br />
									<br />
									<p>
										Приложение позволяет Лицензиатам при наличии доступа во
										всемирную сеть Интернет создавать Персональные страницы
										(аккаунты) и ознакамливаться с Персональными страницами
										(аккаунтами) других Лицензиатов, осуществлять контекстный
										поиск информации о других Лицензиатах, размещать, копировать
										и загружать фотографии и иной контент, пользоваться иными
										функциональными возможностями приложения. Права
										использования данных и команд (активированных и
										неактивированных) передаются Лицензиаром Лицензиату на
										условиях, определенных настоящим Соглашением.
									</p>
									<br />
									<br />
									<p>
										Оперирование и обслуживание приложения, включая техническое
										и организационное сопровождение, осуществляется
										исключительно Лицензиаром и представляет собой его
										обязательство (с учетом положений статьи 5 настоящего
										Соглашения), обеспечивающего возможность предоставления прав
										Лицензиату. Участие Лицензиатов в приложении происходит в
										интерактивном (онлайн) режиме посредством подключения
										Лицензиата через всемирную сеть Интернет. Лицензиар является
										обладателем необходимого объема прав на приложение как
										программу для ЭВМ и на все составляющие его части, взятые
										как в отдельности, так и в совокупности, а также порождаемые
										им аудиовизуальные элементы, за исключением Контента, не
										размещенного в приложении Лицензиаром, права на элементы
										которого могут принадлежать третьим лицам. Лицензиар вправе
										осуществлять использование, оперирование и распространение
										приложения на соответствующих территориях, где он
										обеспечивает его использование, оперирование и
										распространение.
									</p>
								</li>
								<li>
									<strong>Лицензиар</strong> – ООО "ТЕРЕМОК", которое
									предоставляет Лицензиату право использования приложения на
									условиях неисключительной лицензии и осуществляет доведение до
									всеобщего сведения, распространение, оперирование,
									обслуживание, администрирование приложения. Лицензиар является
									Стороной настоящего Соглашения.
								</li>
								<li>
									<strong>Лицензиат</strong> – физическое лицо, обладающего
									право- и дееспособностью для заключения настоящего Соглашения,
									являющегося участником приложения, которому в соответствии с
									настоящим Соглашением предоставляется право использовать
									приложение на условиях настоящего Соглашения. Лицензиат
									является Стороной настоящего Соглашения.
								</li>
								<li>
									<strong>Контент</strong> – размещенные в приложения элементы
									дизайна, иллюстрации, графические изображения, фотографии,
									скрипты, тексты, видео, музыка, звуки и другие объекты,
									являющиеся в том числе результатами интеллектуальной
									деятельности, права использования которых могут принадлежать
									Лицензиару, Лицензиатам или иным лицам.
								</li>
								<li>
									<strong>Персональная страница</strong> (ранее аккаунт и далее)
									– раздел приложения, содержащий часть размещаемой в Аккаунте
									Лицензиата информации (в том числе, по желанию Лицензиата,
									фотографии, сведения о Лицензиате), доступный для ознакомления
									иным Лицензиатам. Персональная страница используется
									Лицензиатом в целях обмена Личными сообщениями и осуществления
									иных действий. Доступ к Персональной странице может быть
									ограничен Лицензиатом для других пользователей в пределах
									доступного функционала приложения или Лицензиаром согласно
									условиям настоящего Соглашения.
								</li>
								<li>
									<strong>Аккаунт Лицензиата</strong> (Аккаунт) — учетная запись
									Лицензиата, создаваемая в момент регистрации в приложении,
									позволяющая Лицензиару учитывать каждого Лицензиата и
									предоставлять права использования приложением посредством
									уникального логина и пароля. Логин и пароль для доступа к
									Аккаунту определяются Лицензиатом самостоятельно при
									регистрации в приложения и могут быть изменены им в порядке,
									описанном в настоящем Соглашении или разделе «Обратная связь»
									на странице приложения.
								</li>
								<li>
									<strong>Личные сообщения</strong> — электронные сообщения,
									передаваемые одним Лицензиатом другому и недоступные для
									ознакомления иным лицам, отправка и получение которых
									осуществляется с использованием Персональной страницы
									Лицензиата.
								</li>
								<li>
									<strong>Лицензионное соглашение</strong> — текст настоящего
									Соглашения, заключаемого между Лицензиаром и Лицензиатом,
									содержащий все необходимые и существенные условия
									лицензионного договора о предоставлении прав использования
									приложения, в том числе неактивированных данных и команд.
									Неотъемлемой частью настоящего Соглашения являются также любые
									иные правила использования приложения, размещенные Лицензиаром
									на соответствующих страницах приложения, в том числе в разделе
									«Обратная связь», включая мобильную версию сайта и приложения.
								</li>
							</ol>
						</li>
						<li>
							<p>
								<strong>Условия присоединения к настоящему Соглашению</strong>
							</p>
							<ol>
								<li>
									Перед тем как начать использование приложения, Лицензиат
									обязан ознакомиться с настоящим Соглашением, а также со всеми
									применимыми в приложении правилами и иными документами,
									которые размещены в соответствующих разделах приложения.
								</li>
								<li>
									После заполнения обязательных полей и ознакомления с настоящим
									Соглашением Лицензиат присоединяется (принимает) настоящее
									Соглашение, путем нажатия кнопки «Зарегистрироваться» или
									аналогичной, что по смыслу ст.ст. 435 и 438 Гражданского
									кодекса РФ является принятием (акцептом) оферты Лицензиара, а
									равно заключением договора, порождающего у Лицензиата
									обязанности соблюдать условия Соглашения, в том числе
									применимых в приложении правил.
								</li>
								<li>
									Фактическое использование приложения без регистрации Аккаунта,
									в форме и в объеме, доступном без регистрации, также является
									акцептом настоящего Соглашения.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Предмет Соглашения</strong></p>
							<ol>
								<li>
									По настоящему Соглашению Лицензиар предоставляет Лицензиату на
									условиях простой неисключительной лицензии право использования
									приложения, включая права использования активированных и
									неактивированных данных и команд, в пределах, определенных
									настоящим Соглашением.
								</li>
								<li>
									Право использования активированных данных и команд
									предоставляется Лицензиату бесплатно.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Пределы использования приложения</strong></p>
							<ol>
								<li>
									Лицензиат вправе использовать приложение следующими способами:
									<ol>
										<li>
											использовать функционал приложения, в том числе,
											участвовать в формировании приложения путем создания
											Аккаунта и Персональной страницы и изменять содержание
											приложения и персональные настройки Аккаунта в ходе такого
											участия посредством размещения и обработки информации и
											Контента, взаимодействия с другими Лицензиатами с
											соблюдением предусмотренных настоящим Соглашением правил;
										</li>
										<li>
											воспроизводить элементы приложения в виде размещенной в
											нем информации и Контента для личного использования
											посредством копирования в память своего персонального
											компьютера и/или мобильного устройства (скачивания). В том
											случае, если элементы Контента являются объектом авторских
											прав или личными изображениями (фотографиями) других
											Лицензиатов или третьих лиц, Лицензиат при таком их
											воспроизведении обязан дополнительно получать согласие
											таких лиц;
										</li>
									</ol>
								</li>
								<li>
									Лицензиату запрещено:
									<ol>
										<li>
											воспроизводить, распространять, перерабатывать в
											коммерческих или некоммерческих целях элементы приложения,
											являющиеся объектом авторских прав Лицензиара, других
											Лицензиатов или третьих лиц, при отсутствии разрешения
											соответствующих правообладателем на совершение данных
											действий;
										</li>
										<li>
											воспроизводить элементы дизайна или пользовательского
											интерфейса приложения при создании сайтов или ведении
											любой коммерческой деятельности в сети Интернет или вне
											ее;
										</li>
										<li>
											распространять в/вне приложения в коммерческих или
											некоммерческих целях аудиовизуальные отображения и учетные
											данные других Лицензиатов, присутствующие в приложении,
											без согласия этих Лицензиатов;
										</li>
										<li>
											передавать предоставленные Лицензиату права использования
											приложения, другим Лицензиатам или третьим лицам
											посредством заключения сублицензионного договора или иным
											способом;
										</li>
										<li>
											нарушать закрепленные в настоящем Соглашении (раздел 5)
											правила использования приложения.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Правила использования приложения</strong></p>
							<ol>
								<li>
									<p>Для использования приложения Лицензиат создает Аккаунт.</p>
									<br />
									<br />
									<p>
										Для входа в приложение Лицензиатом формируются уникальные
										авторизационные данные – логин и пароль, которые являются
										конфиденциальной информацией и не подлежат разглашению, за
										исключением случаев, предусмотренных действующим
										законодательством и/или настоящим Соглашением. Риск
										осуществления мошеннических и иных неправомерных действий с
										Аккаунтом Лицензиата в связи с утратой пароля несет
										Лицензиат. Лицензиату рекомендуется выбирать пароль
										достаточной сложности, чтобы избежать возможность его
										подбора третьими лицами.
									</p>
								</li>
								<li>
									Учетные данные и иные данные Лицензиата обрабатываются
									Лицензиаром в целях надлежащего исполнения настоящего
									Соглашения.
								</li>
								<li>
									<p>
										В целях исполнения Лицензионного соглашения и предоставления
										Лицензиату доступа к использованию функционала приложения,
										Лицензиар развивает, совершенствует, оптимизирует и внедряет
										новый функционал приложения (включая сервисы и продукты
										информационного, коммуникационного, рекламного,
										образовательного, развлекательного и иного характера), в том
										числе с участием аффилированных лиц и/или партнеров. Для
										обеспечения реализации указанных целей Лицензиат соглашается
										и поручает Лицензиару осуществлять с соблюдением применимого
										законодательства обработку (включая сбор, запись,
										систематизацию, накопление, хранение, уточнение (обновление,
										изменение), сопоставление, извлечение, использование,
										обезличивание, блокирование, удаление и уничтожение) учетных
										и иных данных Лицензиата, включая результаты
										автоматизированной обработки таких данных, в том числе в
										виде целочисленных и/или текстовых значений и
										идентификаторов, их передачу аффилированным лицам и/или
										партнерам во исполнение такого поручения на обработку, а
										также осуществлять сбор (получение) его учетных и иных
										данных от аффилированных лиц и/или партнеров.
									</p>
									<br />
									<br />
									<p>
										Под учетными данными понимаются сведения, которые Лицензиат
										предоставляет самостоятельно на этапе регистрации в
										приложении путем заполнения регистрационной формы для
										создания Аккаунта и Персональной страницы Лицензиата и
										некоторые из предоставляемых в процессе его использования.
										Под иными данными понимаются связанные с Лицензиатом данные,
										которые становятся доступными Лицензиару в процессе
										использования Лицензиатом приложения. Такие данные могут
										включать в себя в том числе информацию о технических
										средствах (устройствах) и способах технологического
										взаимодействия с приложением и/или сервисами аффилированных
										лиц и/или партнеров (в т. ч. IP-адрес хоста, вид
										операционной системы Лицензиата, тип браузера,
										географическое положение, данные о провайдере и иное), об
										активности Лицензиата, а также иные данные, получаемые
										указанными способами.
									</p>
									<br />
									<br />
									<p>
										Лицензиару может быть доступна иная информация, относящаяся
										к Лицензиату и оставленная последним по своему усмотрению в
										процессе использования приложения, которая не обрабатывается
										Лицензиаром, в том числе для достижения указанных в
										настоящем соглашении целей.
									</p>
									<br />
									<br />
									<p>
										Обработка учетных и иных данных Лицензиата осуществляется в
										течение всего периода времени с момента регистрации Аккаунта
										Лицензиата и до момента его удаления, если иное не
										предусмотрено действующим законодательством.
									</p>
									<br />
									<br />
									<p>
										В целях надлежащего исполнения условий Лицензионного
										соглашения, Лицензиар принимает меры по обеспечению
										безопасности приложения. Для реализации указанных целей
										Лицензиат соглашается, что учетные и иные данные могут быть
										переданы третьим лицам, в том числе в случаях,
										предусмотренных применимым законодательством, в объеме,
										необходимом для выявления, расследования и пресечения
										противоправных действий.
									</p>
									<br />
									<br />
									<p>
										Лицензиат соглашается на отражение его учетных и иных
										данных, а также иной информации на Персональной странице
										Лицензиата в рамках функционала приложения, а также на то,
										что такие данные будут считаться общедоступными, если иной
										режим доступа к ним не был выбран Лицензиатом в пределах
										функционала приложения. Лицензиат соглашается, что
										информация, включая учетные и иные данные, может быть
										доступна другим пользователям сети Интернет с учетом
										существующего функционала приложения (который может
										изменяться время от времени Лицензиаром). Лицензиар может
										ограничивать использование третьими лицами информации из
										приложения, в том числе в коммерческих целях. Использование
										информации Лицензиаром определяется настоящим Соглашением.
									</p>
								</li>
								<li>
									После регистрации Аккаунта Лицензиат вправе осуществлять
									наполнение Аккаунта, Персональной страницы и других элементов
									приложения Контентом, добавлять фотографии и иные материалы в
									соответствии с предоставленным функционалом и пользоваться
									иными функциями, предоставленными Лицензиаром при
									использовании приложения при условии соблюдения настоящего
									Соглашения.
								</li>
								<li>
									Лицензиат осознает и соглашается, что размещаемые в Аккаунте
									Лицензиата информация и учетные данные доступны для
									ознакомления иным Лицензиатам через Персональную страницу
									Лицензиата, если доступ к ним не ограничен самим Лицензиатом в
									пределах функциональных возможностей приложения.
								</li>
								<li>
									Вход в приложение Лицензиатом, ранее зарегистрировавшим
									Аккаунт, осуществляется каждый раз путем прохождения процедуры
									авторизации – введения логина и пароля Лицензиата, в
									результате автоматической авторизации с использованием
									технологии cookies и иными доступными и разрешенными
									Лицензиаром способами.
								</li>
								<li>
									Лицо, авторизовавшееся в приложении, считается надлежащим
									пользователем Аккаунта, доступ к использованию и управлению
									которого были получены в результате такой авторизации, если
									отсутствуют сведения, подтверждающие иное.
								</li>
								<li>
									При использовании Лицензиатом приложения может применяться
									технология cookies в целях автоматической авторизации
									Лицензиата в приложении, а также для сбора статистических
									данных, в частности о посещаемости приложения.
								</li>
								<li>
									Лицензиат вправе ограничить или запретить использование
									технологии cookies путем применения соответствующих настроек
									браузера.
								</li>
								<li>
									Лицензиар предпринимает коммерчески обоснованные усилия для
									обеспечения функционирования приложения в круглосуточном
									режиме, однако не гарантирует отсутствие перерывов, связанных
									с техническими неисправностями, проведением профилактических
									работ, а также не гарантирует полную или частичную
									работоспособность Приложения. Лицензиар не гарантирует, что
									приложение или любые его элементы будут функционировать в
									любое конкретное время в будущем или что они не прекратят
									работу.
								</li>
								<li>
									Приложение, включая все скрипты, Контент и его оформление,
									мобильную версию, предоставляются «как есть». Лицензиар не
									предоставляет никаких гарантий того, что приложение или его
									элементы могут подходить для конкретных целей использования.
									Лицензиар не может гарантировать и не обещает никаких
									специфических результатов от использования приложения или его
									элементов. Лицензиар не гарантирует соответствие размещенного
									в приложении Контента, в том числе другими Лицензиатами,
									индивидуальным представлениям Лицензиата о морали и
									нравственности.
								</li>
								<li>
									При использовании приложения Лицензиат обязан соблюдать меры
									предосторожности в отношении Контента, особенно Контента,
									размещенного другими Лицензиатами, иных материалов и
									информации; при переходе по размещенным в приложения
									гиперссылкам, при использовании любых файлов, в том числе
									программного обеспечения, во избежание негативного воздействия
									на компьютер Лицензиата вредоносного программного обеспечения,
									недобросовестного доступа к Аккаунту, подборки пароля и прочих
									негативных для Лицензиата последствий.
								</li>
								<li>
									Лицензиат, размещая в приложении Контент, предоставляет
									Лицензиару право использовать Контент исключительно для целей
									функционирования приложения необходимыми для этого способами,
									если иное не предусмотрено иными правилами использования
									приложения.
								</li>
								<li>
									Лицензиат не вправе загружать или иным образом доводить до
									всеобщего сведения (размещать в приложения, публиковать в
									рамках приложения) Контент и прочие результаты
									интеллектуальной деятельности Лицензиатов, Лицензиара и иных
									лиц-правообладателей, при отсутствии явным образом выраженного
									согласия правообладателя и/или необходимого объема прав на
									такие действия.
								</li>
								<li>
									Воспроизведение, копирование, сбор, систематизация, хранение,
									передача информации с приложения в коммерческих целях и/или в
									целях извлечения базы данных приложения в коммерческих или
									некоммерческих целях, или его использования полностью или в
									любой части любым способом, не допускается без согласия
									Лицензиара. Запрещено использование автоматизированных
									скриптов (программ, ботов, краулеров и иных средств
									автоматического сбора информации) для сбора информации и (или)
									взаимодействия с приложением без согласия Лицензиара.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Права и обязанности Лицензиара</strong></p>
							<ol>
								<li>
									Лицензиар осуществляет текущее управление приложением,
									определяет его структуру, внешний вид, разрешает или
									ограничивает доступ Лицензиатов к приложению при нарушении
									положений настоящего Соглашения, осуществляет иные
									принадлежащие ему права. Лицензиат соглашается с тем, что
									Лицензиар вправе использовать функциональные и технические
									возможности программных средств (аудио/видеоплеера),
									обеспечивающих отображение размещенного в рамках приложения
									Контента, по своему усмотрению, в том числе для целей
									отображения рекламы.
								</li>
								<li>
									В части предоставления возможности взаимодействия между
									Лицензиатами, в том числе предоставления Лицензиатам
									возможности самостоятельно совершать те или иные действия в
									рамках приложения, Лицензиар выступает исключительно как лицо,
									предоставившее технологическую возможность такого
									взаимодействия с использованием приложения. Связанные с таким
									взаимодействием передача, хранение и обеспечение доступа
									посредством сети Интернет и программного обеспечения
									приложения к предоставляемой Лицензиатами информации,
									графическим изображениям и иным материалам, осуществляются без
									изменения таких материалов или влияния на их содержание со
									стороны Лицензиара.
								</li>
								<li>
									<p><strong>Лицензиар имеет право:</strong></p>
									<ol>
										<li>
											в любое время изменять оформление и пользовательский
											интерфейс приложения, его содержание, содержание
											предоставляемых функций приложения, изменять или дополнять
											используемые скрипты, программное обеспечение, Контент
											Лицензиара и другие объекты, используемые или хранящиеся в
											приложения, любые Приложения и серверные приложения, с
											уведомлением Лицензиата или без такового;
										</li>
										<li>
											удалять без каких-либо причин и без предупреждения любой
											Контент, в том числе Контент, который по усмотрению
											Лицензиара нарушает и/или может нарушать законодательство
											Российской Федерации (применимое законодательство),
											положения настоящего Соглашения, права других Лицензиатов
											или третьих лиц, причинять им вред или создавать угрозу
											причинения вреда и/или безопасности;
										</li>
										<li>
											по своему усмотрению удалять любую информацию (включая
											Личные сообщения Лицензиата и прочую информацию, и иные
											материалы), в том числе размещаемую Лицензиатом в
											приложении в нарушение законодательства Российской
											Федерации (применимого законодательства) и/или положений
											настоящего Соглашения;
										</li>
										<li>
											приостанавливать, ограничивать или прекращать доступ
											Лицензиата ко всем или к любому из разделов приложения
											и/или элементов приложения, персональной странице,
											функциям приложения, включая активированные и
											неактивированные данные и команды, в любое время без
											объяснения причин, с предварительным уведомлением или без
											такового, если иное прямо не предусмотрено применимым
											законодательством;
										</li>
										<li>
											удалить Аккаунт Лицензиата по своему усмотрению, в том
											числе в случае совершения Лицензиатом действий, нарушающих
											законодательство Российской Федерации (применимое
											законодательство) или положения настоящего Соглашения;
										</li>
										<li>
											осуществлять рассылку Лицензиатам от Лицензиара и/или от
											партнеров Лицензиара сообщений рекламно-информационного
											или новостного характера, в том числе посредством
											электронной почты, sms-рассылки, рассылки с использованием
											функционала приложения или иным доступным способом;
										</li>
										<li>
											в целях сбора статистических данных и идентификации
											Лицензиата устанавливать и сохранять информацию об
											IP-адресах доступа Лицензиата к приложению, использовать
											файлы технической информации (cookies), размещаемые на
											персональном компьютере Лицензиата;
										</li>
										<li>
											при использовании приложения делать замечания Лицензиатам,
											предупреждать, уведомлять, информировать их о несоблюдении
											Лицензиатами настоящего Соглашения. Указания Лицензиара,
											данные Лицензиату во время процесса использования
											приложения, обязательны для исполнения Лицензиатом;
										</li>
										<li>
											предпринимать не запрещенные законом меры для защиты
											собственных интеллектуальных прав в отношении приложения;
										</li>
									</ol>
								</li>
								<li>
									<p>
										<strong>
											Лицензиар принимает на себя следующие обязательства:
										</strong>
									</p>
									<ol>
										<li>
											на условиях, изложенных в настоящем Соглашении,
											предоставить Лицензиату права использования приложения,
											включая права использования неактивированных данных и
											команд, в пределах, установленных настоящим Соглашением;
										</li>
										<li>
											уведомлять Лицензиата путем опубликования информации или
											рассылки сообщений в приложения или иным доступным
											Лицензиару способом об изменениях условий настоящего
											Соглашения.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Права и обязанности Лицензиата</strong></p>
							<ol>
								<li>
									<p><strong>Лицензиат вправе:</strong></p>
									<ol>
										<li>
											производить настройки Аккаунта и Персональной страницы,
											менять логин и пароль для доступа к Аккаунту;
										</li>
										<li>
											размещать в Аккаунте Лицензиата информацию о себе,
											добавлять фотографии, оставлять отзывы о других
											Лицензиатах;
										</li>
										<li>отправлять и получать Личные сообщения;</li>
										<li>
											приобретать права использования неактивированных данных и
											команд и осуществлять реализацию этих прав в соответствии
											с настоящим Соглашением;
										</li>
										<li>
											осуществлять иные, не запрещенные законодательством
											Российской Федерации (применимым законодательством) или
											настоящим Соглашением действия, связанные с использованием
											приложения.
										</li>
									</ol>
								</li>
								<li>
									<p><strong>Лицензиат обязан:</strong></p>
									<ol>
										<li>
											соблюдать условия настоящего Соглашения без каких-либо
											ограничений;
										</li>
										<li>
											в момент регистрации в приложении и создании Аккаунта
											указывать достоверную информацию;
										</li>
										<li>
											не превышать пределов использования приложения,
											установленных в разделе 4 настоящего Соглашения;
										</li>
										<li>
											не нарушать иным образом права интеллектуальной
											собственности Лицензиара в отношении приложения или
											каких-либо его элементов, в частности, Лицензиат не имеет
											права копировать, транслировать, рассылать, публиковать, и
											иным образом распространять и воспроизводить размещенные
											Лицензиаром в приложения материалы (текстовые,
											графические, аудио-видео) без письменного согласия
											Лицензиара;
										</li>
										<li>
											самостоятельно предпринимать должные меры, обеспечивающие
											безопасность его Аккаунта и Персональной страницы и
											предотвращающие несанкционированный доступ к ним третьих
											лиц (в частности, следить за тем, чтобы пароль не
											сохранялся в браузере, в том числе при использовании
											технологии cookies, при возможном использовании
											компьютерного устройства Лицензиата третьими лицами);
										</li>
										<li>
											выполнять указания Лицензиара, касающиеся использования
											приложения, в частности, данные Лицензиаром Лицензиату или
											группе Лицензиатов в приложении, в центре поддержки
											пользователей (Лицензиатов), в новостном разделе
											приложения (онлайн впечатления). В случае невыполнения
											Лицензиатом таких указаний Лицензиар имеет право
											приостановить, ограничить, прекратить предоставление
											Лицензиату прав использования приложения, включая права
											использования неактивированных данных и команд;
										</li>
										<li>
											по требованию Лицензиара в связи с заключением и
											исполнением настоящего Соглашения подтвердить свои учетные
											данные, включающие телефон (логин), пароль и иные данные;
										</li>
										<li>
											не размещать фотографии, на которых помимо Лицензиата
											запечатлены другие лица, без их предварительного согласия,
											за исключением случаев, когда в соответствии со статьей
											152.1. ГК РФ или иной нормой применимого законодательства,
											такое согласие не требуется;
										</li>
										<li>
											уведомлять Лицензиара обо всех случаях совершения в
											приложении в отношении Лицензиата действий, которые могут
											быть расценены как оскорбительные, унижающие,
											дискредитирующие и т.п.;
										</li>
										<li>
											периодически знакомиться с содержанием настоящего
											Соглашения в сети Интернет по адресу
											<a href="https://teremok.io/licence">
												https://teremok.io/licence
											</a>
											и следить за внесенными в него изменениями;
										</li>
										<li>
											соблюдать иные требования и выполнять иные обязательства,
											предусмотренные настоящим Соглашением и/или размещенные в
											соответствующих разделах приложения;
										</li>
										<li>
											Лицензиат гарантирует, что обладает всеми необходимыми
											полномочиями и дееспособностью для заключения настоящего
											Соглашения.
										</li>
									</ol>
								</li>
								<li>
									<p><strong>Лицензиату запрещается:</strong></p>
									<ol>
										<li>
											осуществлять сбор учетных данных других Лицензиатов и/или
											иной информации из приложения без согласия Лицензиара;
										</li>
										<li>
											использовать любые автоматические или автоматизированные
											средства для сбора информации, размещенной в приложении;
										</li>
										<li>
											осуществлять пропаганду или агитацию, возбуждающую
											социальную, расовую, национальную или религиозную
											ненависть и вражду, пропаганду войны, социального,
											расового, национального, религиозного или языкового
											превосходства, распространять иную информацию, запрещенную
											к распространению применимым законодательством;
										</li>
										<li>
											размещать в приложении или передавать посредством Личных
											сообщений информацию ограниченного доступа
											(конфиденциальную информацию) третьих лиц, если Лицензиат
											не обладает достаточными правами в силу закона или
											договора на раскрытие данной информации;
										</li>
										<li>
											размещать, воспроизводить, перерабатывать, распространять,
											опубликовывать в приложения, доводить до всеобщего
											сведения, передавать, продавать или иным способом
											использовать целиком или по частям Контент Лицензиара,
											Лицензиатов и третьих лиц, являющийся объектом авторских и
											иных исключительных прав, без их предварительного
											разрешения, кроме случаев, установленных настоящим
											Соглашением, действующим законодательством Российской
											Федерации (применимым законодательством), а также случаев,
											когда правообладатель явным образом выразил свое согласие
											на свободное использование собственного Контента любым
											лицом. Загружая Контент в приложение, а также размещая
											Контент в приложении иным образом, Лицензиат подтверждает,
											что обладает всеми необходимыми для этого правами, включая
											право на доведение до всеобщего сведения, что такое
											размещение не нарушает и не будет нарушать права и
											законные интересы правообладателя и третьих лиц, и что все
											необходимые разрешения и согласия со стороны
											соответствующих правообладателем и третьих лиц были в
											надлежащем порядке и явным образом для этого получены. В
											приложении запрещается размещать Контент, если Лицензиат
											не имеет для этого необходимых прав и/или согласия
											правообладателя;
										</li>
										<li>
											размещать в приложении в открытом доступе или передавать
											посредством Личных сообщений текстовые сообщения,
											графические изображения или иные материалы, содержание
											которых является оскорбительным для других Лицензиатов или
											иных лиц или может быть расценено в качестве такового, а
											также сообщения, изображения и иные материалы, которые
											дискредитируют Лицензиатов или иных лиц, содержат угрозы,
											призывы к насилию, совершению противоправных деяний,
											антиобщественных, аморальных поступков, а также совершению
											любых иных действий, противоречащих основам правопорядка и
											нравственности;
										</li>
										<li>
											размещать в приложении сообщения, графические изображения
											или другие материалы (в том числе не соответствующие
											действительности), размещение которых наносит или может
											нанести ущерб чести, достоинству и деловой репутации
											гражданина или деловой репутации организации;
										</li>
										<li>
											размещать в приложении сообщения, содержащие нецензурные
											слова и выражения;
										</li>
										<li>
											размещать в приложении материалы порнографического
											характера с участием несовершеннолетних;
										</li>
										<li>
											размещать в приложения информацию, которая пропагандирует
											суицид, содержит описание способов суицида и любое
											подстрекательство к его совершению;
										</li>
										<li>
											размещать в приложении рекламу и иную информацию о
											наркотических и психотропных веществах, в том числе,
											информацию о распространении наркотиков, рецепты их
											изготовления и советы по употреблению, а также размещать
											информацию экстремистской направленности;
										</li>
										<li>
											размещать в приложении информацию, которая нарушает права
											несовершеннолетних лиц;
										</li>
										<li>
											размещать в приложении любую информацию противоправного
											характера;
										</li>
										<li>
											размещать в приложении персональные данные, в том числе
											контактные данные, других Лицензиатов или иных лиц без их
											предварительного согласия;
										</li>
										<li>
											указывать при регистрации Аккаунта или вводить
											впоследствии заведомо ложную информацию или чужие данные;
										</li>
										<li>
											размещать в приложении в качестве собственной фотографии
											изображения других лиц без согласия таких лиц или
											вымышленных персонажей, изображения животных, предметов,
											абстрактные изображения, а также любые иные графические
											изображения, не являющиеся изображениями Лицензиата,
											размещающего данные изображения, без соответствующего
											согласия правообладателей (авторов) таких изображений;
										</li>
										<li>
											регистрировать Аккаунт Лицензиата в целях использования
											группой лиц;
										</li>
										<li>
											регистрировать более одного Аккаунта Лицензиата одним и
											тем же лицом;
										</li>
										<li>
											осуществлять действия, направленные на дестабилизацию
											функционирования приложения, осуществлять попытки
											несанкционированного доступа к управлению приложением или
											его закрытым разделам (в том числе к разделам, доступ к
											которым разрешен только Лицензиару), а также осуществлять
											любые иные аналогичные действия;
										</li>
										<li>
											осуществлять несанкционированный доступ к Аккаунтам иных
											Лицензиатов путем подборки или введения пароля, а также
											предпринимать попытки такого доступа;
										</li>
										<li>
											осуществлять рассылку спама – массовую рассылку
											коммерческой, политической, рекламной и иной информации (в
											том числе гиперссылок, ведущих на интернет-сайты с такой
											информацией и/или на интернет-сайты, содержащие
											вредоносное программное обеспечение) в Личных сообщениях
											или осуществляя иные действия, направленные на
											распространение такой информации, если
											Лицензиаты-получатели не выражали своего согласия на
											получение такого рода информации;
										</li>
										<li>
											использовать приложение с целью поиска должников или для
											иных подобных целей;
										</li>
										<li>
											размещать в приложении информацию, в том числе и в
											объявлениях, способствующую продвижению, рекламе и
											популяризации табака и табачных изделий, лекарственных
											препаратов, наркотических веществ, крепких и
											слабоалкогольных напитков;
										</li>
										<li>
											размещать в приложении иную информацию, которая, по мнению
											Лицензиара, не соответствует политике и целям создания
											приложения.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Гарантии и ответственность</strong></p>
							<ol>
								<li>
									Лицензиат гарантирует, что размещение им в приложении или
									передача посредством Личных сообщений информации, Контента,
									иных охраняемых законом результатов интеллектуальной
									деятельности, их частей или копий, а также иных материалов не
									нарушает чьих-либо прав и законных интересов. В случае
									поступления претензий третьих лиц в связи с нарушением условий
									настоящего Соглашения в части размещения информации и/или
									Контента третьих лиц, Лицензиар вправе осуществить передачу в
									рамках, допустимых законом, доступной ему контактной
									информации Лицензиата лицам, обратившимся с претензиями, в
									целях урегулирования возникших разногласий.
								</li>
								<li>
									Лицензиат гарантирует, что им будут приняты надлежащие меры
									для обеспечения конфиденциальности учетных данных (логина и
									пароля), используемых им для авторизации в приложении, и
									предотвращения возможности авторизации другими лицами.
								</li>
								<li>
									Лицензиар соблюдает тайну переписки, осуществляемой
									Лицензиатами посредством Личных сообщений.
								</li>
								<li>
									Лицензиар гарантирует использование со стороны Лицензиара
									указанных в Аккаунте Лицензиата адреса электронной почты и
									номера мобильного телефона для связи Лицензиара с Лицензиатом,
									отправки Лицензиату уведомлений, сообщений и sms-сообщений, а
									также для предоставления Лицензиату функций приложения с
									согласия Лицензиата, которое может быть выражено при помощи
									функциональных возможностей приложения, если иное не
									предусмотрено настоящим Соглашением или действующим
									законодательством.
								</li>
								<li>
									Лицензиар не участвует в формировании содержания Персональной
									страницы Лицензиата и загрузке им Контента, не контролирует
									действия Лицензиата, не осуществляет и не имеет технической
									возможности осуществлять автоматическую цензуру информации в
									открытых разделах приложения, в Персональных страницах
									Лицензиата, цензуру Личных сообщений и не несет
									ответственности за действия или бездействие Лицензиата (за
									исключением специальных технических решений, которые могут
									реализовываться в целях предотвращения и пресечения нарушений
									прав третьих лиц на результаты интеллектуальной деятельности).
								</li>
								<li>
									В силу особенностей функционирования приложения, Лицензиар не
									осуществляет и не имеет технической возможности осуществлять
									предварительную модерацию информации и Контента, размещаемого
									Лицензиатом, и не несет ответственности за его содержание.
								</li>
								<li>
									Лицензиар не несет ответственности за возможные сбои и
									перерывы в работе приложения и вызванные ими потерю
									информации. Лицензиар не несет ответственности за любой ущерб
									компьютеру Лицензиата, мобильным устройствам, любому другому
									оборудованию или программному обеспечению, вызванный или
									связанный с использованием приложения или сайтов, доступных по
									гиперссылкам, размещенным в приложении.
								</li>
								<li>
									Лицензиар не несет ответственности за подбор пароля доступа к
									Аккаунту Лицензиата третьими лицами и любые действия,
									совершенные ими с использованием Аккаунта Лицензиата.
								</li>
								<li>
									Лицензиар не несет ответственности за любой ущерб, включая
									упущенную выгоду, или вред, вызванные в связи с использованием
									приложения, размещенного в нем Контента или иных материалов, к
									которым Лицензиат или иные лица получили доступ при помощи
									приложения, даже если Лицензиар предупреждал или указывал на
									возможность причинения такого ущерба или вреда.
								</li>
								<li>
									Лицензиат несет ответственность за противоправные действия,
									осуществляемые с использованием его Аккаунта, а также в связи
									с размещением Контента с использованием его Аккаунта в
									приложения, на Персональной странице Лицензиата или других
									Лицензиатов, в записях на стене, в комментариях и в иных
									разделах приложения и/или приложения.
								</li>
								<li>
									Лицензиат несет персональную ответственность за любой Контент
									или иную информацию, которые он размещает в приложении или
									иным образом доводит до всеобщего сведения в приложении или с
									его помощью. Лицензиат обязуется самостоятельно разрешать
									претензии третьих лиц, связанные с неправомерным размещением
									Контента и информации в приложении.
								</li>
								<li>
									Гиперссылки на любой сайт, продукт, услугу, любую информацию
									коммерческого или некоммерческого характера, размещенные в
									приложении, размещаемые в профилях Персональных страниц
									Лицензиатами и т.п. не являются одобрением или рекомендацией
									данных продуктов (услуг) со стороны Лицензиара. Лицензиар не
									несет ответственности за ущерб, причиненный Лицензиату в
									результате перехода по таким гиперссылкам.
								</li>
								<li>
									Лицензиар не несет ответственности за возможные противоправные
									действия Лицензиата или третьих лиц при использовании
									приложения.
								</li>
								<li>
									Лицензиар не несет ответственности за высказывания Лицензиата,
									опубликованные в приложении. Лицензиар не несет
									ответственности за поведение Лицензиата в приложении,
									неуважительное отношение к другим Лицензиатам.
								</li>
								<li>
									Лицензиар не несет ответственности за утерю Лицензиатом
									возможности доступа к своему Аккаунту в приложении (утрату
									логина, пароля, иной информации, необходимой для использования
									Аккаунта или Персональной страницы).
								</li>
								<li>
									Лицензиар не несет ответственности за неполное, неточное,
									некорректное указание Лицензиатом своих данных при создании
									Аккаунта и Персональной страницы.
								</li>
								<li>
									Лицензиар не несет ответственности за отсутствие у Лицензиата
									доступа в Интернет, за качество услуг провайдеров связи сети
									Интернет, с которыми Лицензиатом заключены соглашения о
									предоставлении услуг по доступу к сети Интернет.
								</li>
								<li>
									Лицензиар не возмещает Лицензиату расходы, связанные с
									приобретением Лицензиатом прав использования, неактивированных
									данных и команд, в том числе в случае приостановления или
									прекращения настоящего Соглашения по любым основаниям, если
									иное прямо не предусмотрено применимым законодательством.
								</li>
								<li>
									Лицензиар не несет ответственности за возникновение прямого
									или косвенного ущерба и упущенной выгоды Лицензиата либо иных
									третьих лиц, причиненного в результате:
									<ol>
										<li>
											использования либо невозможности использования приложения;
										</li>
										<li>
											несанкционированного доступа любых третьих лиц к личной
											информации Лицензиата, включая Аккаунт и Персональную
											страницу Лицензиата;
										</li>
										<li>
											заявления или поведения любого третьего лица в приложения;
										</li>
										<li>
											удаления Аккаунта, Контента или прекращением
											функционирования определенного элемента приложения или
											приложения в целом.
										</li>
									</ol>
								</li>
								<li>
									При любых обстоятельствах ответственность Лицензиара перед
									Лицензиатом ограничена суммой в 3 000 (три тысячи) рублей, при
									этом упущенная выгода возмещению не подлежит, если иное прямо
									не предусмотрено применимым законодательством.
								</li>
								<li>
									Лицензиар не обязан предоставлять Лицензиату какие-либо
									доказательства, документы и прочего, свидетельствующие о
									нарушении Лицензиатом условий Соглашения, в результате
									которого Лицензиату было отказано в предоставлении прав
									использования приложения или его отдельных
									функций/разделов/страниц, а также прав на неактивированные
									данные и команды.
								</li>
								<li>
									Претензии Лицензиата, направляемые Лицензиару, принимаются и
									рассматриваются при условии возможности установить факт
									принадлежности Аккаунта конкретному лицу. Принимая во внимание
									возможное наличие Аккаунтов со схожими учетными данными,
									Лицензиар вправе требовать предоставления дополнительных
									сведений и информации, в том числе в отношении Аккаунта
									Лицензиата, позволяющих определить, в связи с каким Аккаунтом
									поступила претензия, или установить принадлежность Аккаунта
									лицу, обратившемуся с претензией.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Территория и срок действия Соглашения</strong></p>
							<ol>
								<li>
									Лицензиат вправе использовать приложение способами, описанными
									в настоящем Соглашении, на всей территории Российской
									Федерации, а также иных территориях, на которых она доступна с
									использованием стандартных компьютерных средств, программ и
									устройств.
								</li>
								<li>
									Настоящего Соглашение действует с момента принятия его условий
									Лицензиатом и действует в течение 1 (одного) календарного
									года.
								</li>
								<li>
									Действие настоящего Соглашения автоматически продлевается еще
									на каждый последующий 1 (один) год, если до истечения
									указанного срока действия Соглашения:
									<ol>
										<li>
											Лицензиар не примет решение об изменении положений
											настоящего Соглашения, о необходимости заключения с
											Лицензиатами нового соглашения, прекращении
											администрирования и обслуживания приложения и прекращении
											к нему доступа, прекращении настоящего Соглашения в
											отношении Лицензиата, или прекращения доступа к
											использованию приложения в отношении Лицензиата.
										</li>
										<li>
											Лицензиат не примет решение о прекращении использования
											приложения и не удалит свой Аккаунт.
										</li>
									</ol>
								</li>
								<li>
									Лицензиар вправе в любое время без уведомления Лицензиата и
									без объяснения причин прекратить настоящее Соглашение в
									одностороннем внесудебном порядке с немедленным прекращением
									доступа и возможности использовать приложение и без возмещения
									каких-либо затрат, убытков или возврата полученного по
									Соглашению, в том числе в случаях закрытия приложения, любого,
									в том числе однократного, нарушения Лицензиатом условий
									настоящего Соглашения, а также любых других случаях, указанных
									Лицензиаром на страницах приложения, если иное прямо не
									предусмотрено применимым законодательством.
								</li>
								<li>
									Лицензиар вправе в любое время без уведомления Лицензиата и
									без объяснения причин приостановить доступ и возможность
									использовать приложение, удалить Аккаунт Лицензиата без
									возмещения каких-либо затрат, убытков или возврата,
									полученного по Соглашению, в том числе в случае любого, в том
									числе однократного, нарушения Лицензиатом условий настоящего
									Соглашения, если иное прямо не предусмотрено применимым
									законодательством.
								</li>
								<li>
									Лицензиат соглашается и полностью признает, что все
									исключительные права или необходимые лицензии на программное
									обеспечение, составляющего элемент приложения и/или
									используемое для его администрирования и функционирования,
									включая входящие в его состав аудиовизуальные отображения, а
									также графический дизайн приложения, фотографии, анимации,
									видеоизображения, видеоклипы, звуковые записи, звуковые
									эффекты, музыка, текстовое наполнение приложения, за
									исключением Контента, загружаемого в приложение Лицензиатами
									или третьими лицами, принадлежат Лицензиару, если иное в явном
									виде не указано в Соглашении.
								</li>
								<li>
									Настоящее Соглашение не предусматривает уступку каких-либо
									исключительных прав или выдачу исключительной лицензии на
									любые составляющие части приложения от Лицензиара к
									Лицензиату.
								</li>
								<li>
									В случае, если Лицензиату в соответствии с законами его
									государства запрещено пользоваться сетью Интернет или
									сервисами услуг, или существуют иные законодательные
									ограничения, включая ограничения по возрасту допуска к такому
									программному обеспечению, Лицензиат не вправе использовать
									приложение. В таком случае Лицензиат самостоятельно несет
									ответственность за использование приложения на территории
									своего государства в нарушение местного законодательства.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Заключительные положения</strong></p>
							<ol>
								<li>
									Настоящее Соглашение может быть изменено Лицензиаром без
									какого-либо предварительного уведомления. Настоящее Соглашение
									может быть изменено и/или дополнено Лицензиаром в
									одностороннем порядке без какого-либо специального
									уведомления. Лицензиат обязуется самостоятельно проверять
									Соглашение на предмет изменений. Неосуществление Лицензиатом
									действий по ознакомлению с Соглашением и/или измененной
									редакции Соглашения не может служить основанием для
									неисполнения Лицензиатом своих обязательств и несоблюдения
									Лицензиатом ограничений, установленных настоящим Соглашением.
									Фактическое использование Лицензиатом приложения после
									внесения изменений в условия настоящего Соглашения или правила
									его использования, означает согласие Лицензиата с новыми
									условиями.
								</li>
								<li>
									Недействительность одного или нескольких положений Соглашения,
									признанная в установленном порядке вступившим в силу решением
									суда, не влечет для Сторон недействительности соглашения в
									целом. В случае признания одного или нескольких положений
									Соглашения в установленном порядке недействительными, Стороны
									обязуются исполнять взятые на себя по Соглашению обязательства
									максимально близким к подразумеваемым Сторонами при заключении
									и/или согласованном изменении Соглашения способом.
								</li>
								<li>
									Настоящее Соглашение и взаимоотношения Сторон в связи с
									исполнением настоящего Соглашения и использованием приложения
									регулируются законодательством Российской Федерации.
								</li>
								<li>
									В отношении формы и способа заключения настоящего Соглашения
									применяются нормы Гражданского Кодекса РФ («ГК РФ»),
									регулирующие порядок и условия заключения договора путем
									акцепта публичной оферты.
								</li>
								<li>
									Все споры сторон по настоящему соглашению подлежат разрешению
									путем переписки и переговоров с использованием обязательного
									досудебного (претензионного) порядка. В случае невозможности
									достичь согласия между сторонами путем переговоров в течение
									60 (шестидесяти) календарных дней с момента получения другой
									Стороной письменной претензии, рассмотрение спора должно быть
									передано любой заинтересованной стороной в суд общему
									юрисдикции по месту нахождения Лицензиара (с исключением
									подсудности дела любым иным судам), если иное прямо не
									предусмотрено применимым законодательством.
								</li>
								<li>
									Редакция настоящего Соглашения доступна в сети Интернет по
									адресу размещения приложения, в соответствующем разделе и иных
									местах, определенных Лицензиаром и позволяющих Лицензиату
									ознакомиться с его содержанием.
								</li>
								<li>
									По вопросам, связанным с исполнением Соглашения, просьба
									обращаться по адресу местонахождения Лицензиара: 143085,
									Московская область, г.о. Одинцовский, рп. Заречье, ул.
									Весенняя, д. 1, к. 5, кв. 5
								</li>
							</ol>
						</li>
					</ol>
					<p>Редакция от «7» марта 2023 года.</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'LicenseGuest',
	methods: {
		goBack() {
			if (window.history.length > 2) {
				this.$router.go(-1)
			} else {
				window.close()
			}
		}
	}
}
</script>
